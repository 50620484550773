@import url(https://fonts.googleapis.com/css2?family=Neucha&display=swap);
html {
  font-family: sans-serif;
}

body {
  scrollbar-width: thin;
  margin: 0;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  height: 12px;
  width: 5px;
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #5f5f5f;
}
.App {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2f74c0;
  font-family: "Neucha", cursive;
}

.heading {
  text-transform: uppercase;
  font-size: 40px;
  margin: 30px 0;
  color: white;
  z-index: 1;
  text-align: center;
}

@media (max-width: 800px) {
  .heading {
    margin: 15px 0;
    font-size: 35px;
  }
}
.input {
    display: flex;
    width: 95%;
    position: relative;
    align-items: center;
  }
  
  .input__box {
    width: 100%;
    border-radius: 50px;
    padding: 20px 30px;
    font-size: 25px;
    border: none;
    transition: 0.2s;
    box-shadow: inset 0 0 5px black;
  }
  
  .input__box:focus {
    box-shadow: 0 0 10px 1000px rgba(0, 0, 0, 0.5);
    outline: none;
  }
  
  .input_submit {
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 12px;
    border-radius: 50px;
    right: 0px;
    border: none;
    font-size: 15px;
    background-color: #2f74c0;
    color: white;
    transition: 0.2s all;
    box-shadow: 0 0 10px black;
  }
  
  .input_submit:hover {
    background-color: #052344;
  }
  
  .input_submit:active {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    box-shadow: 0 0 5px black;
  }
  
  .container {
    width: 95%;
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .todos {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 47.5%;
    padding: 15px;
    background-color: rgb(50, 195, 205);
  }
  
  .dragactive {
    background-color: rgb(0, 221, 236);
  }
  
  .dragcomplete {
    background-color: rgb(255, 38, 0);
  }
  
  .remove {
    background-color: rgb(235, 103, 80);
  }
  
  .todos__heading {
    font-size: 30px;
    /* text-align: center; */
    color: white;
  }
  
  .todos__single {
    display: flex;
    border-radius: 5px;
    padding: 20px;
    margin-top: 15px;
    background-image: url("https://img.freepik.com/free-photo/crumpled-yellow-paper-background-close-up_60487-2390.jpg?size=626&ext=jpg");
    transition: 0.2s;
  }
  
  .drag {
    box-shadow: 0 0 20px black;
  }
  
  .todos__single:hover {
    box-shadow: 0 0 5px black;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
  
  .todos__single--text {
    flex: 1 1;
    padding: 5px;
    border: none;
    font-size: 20px;
  }
  
  .todos__single--text:focus {
    outline: none;
  }
  
  .icon {
    margin-left: 10px;
    font-size: 25px;
    cursor: pointer;
  }
  
  @media (max-width: 1100px) {
    .todos {
      width: 45%;
    }
  }
  
  @media (max-width: 700px) {
    .input {
      width: 95%;
    }
    .container {
      width: 95%;
      flex-direction: column;
    }
    .todos {
      width: 95%;
      margin-bottom: 10px;
    }
  }
